import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

export const SignOut = () => {
  const { logout } = useAuth0()

  useEffect(() => {
    logout({ returnTo: `${process.env.NEXT_PUBLIC_API_URL}/signin` })
  }, [])

  return <></>
}
